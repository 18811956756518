import React, { useEffect } from 'react';
import { css } from '@compiled/react';

import type { CommentAction } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import type { CommentData } from '@confluence/comments-data';
import type { FlagsStateContainer } from '@confluence/flags';
import { CommentBatchList } from '@confluence/comment/entry-points/CommentBatchList';
import { useCommentsPanelActions } from '@confluence/comments-panel-utils';
import { getCommentThreadsToBatch } from '@confluence/comments-panel';

import { ObjectCommentThread } from './ObjectCommentThread';

type CommentListProps = {
	commentThreads: CommentData[];
	supportedTopLevelActions: CommentAction[];
	flags?: FlagsStateContainer;
};

const listStyles = css({
	overflowY: 'auto',
	overflowX: 'hidden',
	flexGrow: 1,
	display: 'block',
});

// this component holds a list of comments
export const ObjectCommentsList = ({
	commentThreads,
	supportedTopLevelActions,
	flags,
}: CommentListProps) => {
	const { setCurrentlyRenderedThreads } = useCommentsPanelActions();

	// Whenever the list that we're rendering here changes, we want to update the list that the keyboard shortcuts are listening to
	useEffect(() => {
		// Include the thread ID along with the location
		setCurrentlyRenderedThreads(
			commentThreads.map((thread) => ({ ...thread.location, id: thread.id })),
		);

		return () => {
			// When this component is unmounted, we want to clear the list that the keyboard shortcuts are listening to
			setCurrentlyRenderedThreads([]);
		};
	}, [commentThreads, setCurrentlyRenderedThreads]);

	// Props we want the batch renderer to pass through that aren't comment specific
	const passThroughProps = {
		supportedTopLevelActions,
		flags,
	};

	return (
		<div
			id="comments-panel-list-container"
			data-testid="comments-panel-list-container"
			css={listStyles}
		>
			<CommentBatchList
				commentsList={commentThreads}
				passThroughProps={passThroughProps}
				Component={ObjectCommentThread}
				getElementsToBatch={getCommentThreadsToBatch}
			/>
		</div>
	);
};
