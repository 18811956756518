import React, { useEffect, useRef, useMemo } from 'react';

import { Stack, xcss } from '@atlaskit/primitives';

import { ReactionsProvider } from '@confluence/comment-context';
import { withErrorBoundary, Attribution } from '@confluence/error-boundary';
import { withFlags } from '@confluence/flags';
import type { WithFlagsProps } from '@confluence/flags';
import { usePageContentId } from '@confluence/page-context';
import { fg } from '@confluence/feature-gating';
import { useCommentsData, useActiveCommentsQuery } from '@confluence/comments-data';
import {
	useCommentsPanel,
	ViewValues,
	getOpenCommentThreads,
	LocationValue,
} from '@confluence/comments-panel-utils';
import { CommentPlaceholder } from '@confluence/comment-simple/entry-points/CommentPlaceholder';

import { ObjectAddCommentContainer } from './ObjectAddCommentContainer';
import { ObjectCommentsList } from './ObjectCommentsList';

const commentPanelContainerStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	height: '100%',
	width: '100%',
});

type CommentsQueryHookVariables = {
	pageId: string;
};

const ObjectCommentContainerComponent = withFlags(({ flags }: WithFlagsProps) => {
	const [contentId] = usePageContentId();
	const pageContainsBodiedMacrosRef = useRef(false);

	const [
		{ orderedActiveAnnotationIdList, commentsDataMap, removedThreadsMap },
		{ clearRemovedComments },
	] = useCommentsData();
	const [
		{ initialDataLoadedForViewMap, showInlineComments, showGeneralComments },
		{ setInitialDataLoadedForView },
	] = useCommentsPanel();
	const isInitialCommentDataLoaded = initialDataLoadedForViewMap[ViewValues.OPEN];

	const commentsQueryHook = useActiveCommentsQuery;
	const commentsQueryHookVariables: CommentsQueryHookVariables = {
		pageId: contentId || '',
	};

	const { loading, error } = commentsQueryHook(commentsQueryHookVariables);

	// We need to check that there are page properties macros or excerpt macros on the page
	// so we can hide the delete option which causes weird issues in the document
	useEffect(() => {
		const bodiedMacroElem = document.querySelector('.ak-renderer-extension');
		pageContainsBodiedMacrosRef.current = Boolean(bodiedMacroElem);
	}, []);

	useEffect(() => {
		// When this view unmounts, we want to clear out the "resolved"/"deleted" comments
		return () => {
			clearRemovedComments();
		};
	}, [clearRemovedComments]);

	useEffect(() => {
		if (!loading) {
			setInitialDataLoadedForView({ viewToSetLoaded: ViewValues.OPEN });
		}
	}, [loading, setInitialDataLoadedForView]);

	const commentThreads = useMemo(() => {
		if (
			(!isInitialCommentDataLoaded && loading) ||
			(orderedActiveAnnotationIdList.length === 0 && Object.keys(removedThreadsMap).length === 0)
		) {
			return [];
		}

		return getOpenCommentThreads({
			commentsDataMap,
			orderedActiveAnnotationIdList,
			removedThreadsMap,
			showInlineComments,
			showGeneralComments,
			location: LocationValue.FOOTER,
		});
	}, [
		loading,
		isInitialCommentDataLoaded,
		commentsDataMap,
		orderedActiveAnnotationIdList,
		removedThreadsMap,
		showInlineComments,
		showGeneralComments,
	]);

	if (!isInitialCommentDataLoaded) {
		if (loading) {
			return <CommentPlaceholder showPlaceholder />;
		}

		if (error) {
			return null;
		}
	}

	const showDeleteOption =
		fg('confluence_frontend_nested_renderer_delete') || !pageContainsBodiedMacrosRef.current;

	return (
		<>
			<Stack xcss={commentPanelContainerStyles}>
				<ReactionsProvider contentId={contentId ?? ''}>
					<ObjectCommentsList
						commentThreads={commentThreads}
						supportedTopLevelActions={
							showDeleteOption ? ['edit', 'resolve', 'delete'] : ['edit', 'resolve']
						}
						flags={flags}
					/>
				</ReactionsProvider>
			</Stack>
			<ObjectAddCommentContainer />
		</>
	);
});

export const ObjectCommentContainer = withErrorBoundary({
	attribution: Attribution.COMMENTS,
})(ObjectCommentContainerComponent);
