import React, { useRef, useEffect } from 'react';
import { useIntl, defineMessages } from 'react-intl-next';

import AddCommentIcon from '@atlaskit/icon/core/comment';
import { xcss, Box, Text, Pressable, Flex } from '@atlaskit/primitives';

const addCommentButtonStyle = xcss({
	display: 'flex',
	minHeight: '32px',
	padding: 'space.0',
	paddingLeft: 'space.150',
	paddingRight: 'space.150',
	justifyContent: 'center',
	alignItems: 'center',
	borderRadius: 'border.radius.100',
	backgroundColor: 'color.background.neutral.subtle',
	':hover': {
		cursor: 'pointer',
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
	color: 'color.text.subtle',
});

const i18n = defineMessages({
	addCommentPromptText: {
		id: 'object-comments.add-comment-prompt.text',
		defaultMessage: 'Add a comment',
		description: 'Label for the add comment prompt',
	},
});

export const ObjectAddCommentPrompt = ({
	onClick,
	autofocusRef,
}: {
	onClick: () => void;
	autofocusRef: { current: boolean | null };
}) => {
	const { formatMessage } = useIntl();

	const buttonRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		if (autofocusRef.current) {
			buttonRef.current?.focus();
			autofocusRef.current = false;
		}
	}, [autofocusRef]);

	return (
		<Box>
			<Pressable
				xcss={addCommentButtonStyle}
				onClick={onClick}
				data-testId="add-comment-button"
				ref={buttonRef}
			>
				<Flex alignItems="center" gap="space.100">
					<AddCommentIcon label={formatMessage(i18n.addCommentPromptText)} />
					<Text>{formatMessage(i18n.addCommentPromptText)}</Text>
				</Flex>
			</Pressable>
		</Box>
	);
};
