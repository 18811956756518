import { useQuery, useMutation } from '@apollo/react-hooks';
import React, { useContext, useState, useRef } from 'react';

import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useSessionData } from '@confluence/session-data';
import type { UserQueryType, UserQueryVariables } from '@confluence/inline-comments-queries';
import { UserQuery } from '@confluence/inline-comments-queries';
import { ProfileAvatar } from '@confluence/profile';
import { PageCommentEditor, type CommentSaveHandler } from '@confluence/comment-simple';
import {
	ReactionsContext,
	useCommentsContentState,
	useCommentsContentActions,
} from '@confluence/comment-context';
import {
	ADD_PAGE_COMMENT_EXPERIENCE,
	ADD_PAGE_COMMENT_LOAD_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import type { GraphQLContentStatus } from '@confluence/comments-panel-queries';
import { CreateGeneralCommentMutation } from '@confluence/page-comments-queries/entry-points/CreateGeneralCommentMutation.graphql';
import {
	Platform,
	ContentRepresentation,
} from '@confluence/page-comments-queries/entry-points/__types__/CreateGeneralCommentMutation';
import type {
	CreateGeneralCommentMutationData,
	CreateGeneralCommentMutationVariables,
} from '@confluence/page-comments-queries/entry-points/__types__/CreateGeneralCommentMutation';
import { updateApolloCacheParentCallback } from '@confluence/page-comments-queries/entry-points/pageCommentUtils';
import { usePageContentId, useContentType } from '@confluence/page-context';
import { useSearchSessionId } from '@confluence/search-session';
import { CommentType, useCommentsDataActions } from '@confluence/comments-data';
import type { CommentData } from '@confluence/comments-data';
import { useUnreadCommentsActions } from '@confluence/unread-comments';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { PageMode } from '@confluence/page-utils/entry-points/enums';
import { END } from '@confluence/navdex';
import { useCommentsPanelScroll } from '@confluence/comments-panel-utils';
import { AnalyticsSource } from '@confluence/comments-util/entry-points/analytics';

import { ObjectAddCommentPrompt } from './ObjectAddCommentPrompt';

const addCommentContainerStyles = xcss({
	display: 'flex',
	width: '100%',
	padding: 'space.200',
	borderTop: `1px solid ${token('color.border')}`,
	background: 'color.text.inverse',
	height: 'auto',
});

const addCommentClosedContainerStyles = xcss({
	width: '100%',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	flexShrink: 0,
});

const addCommentOpenContainerStyles = xcss({
	display: 'flex',
	flexDirection: 'row',
	width: '100%',
	gap: 'space.200',
	alignItems: 'flex-start',
});

const addCommentEditorStyles = xcss({
	width: '100%',
});

const avatarStyles = xcss({
	width: '32px',
	height: '32px',
	float: 'left',
});

export const ObjectAddCommentContainer = () => {
	const [expanded, setExpanded] = useState(false);
	const { userId } = useSessionData();
	const shouldAutofocusPromptRef = useRef<boolean>(false);

	const [createGeneralCommentFn] = useMutation<
		CreateGeneralCommentMutationData,
		CreateGeneralCommentMutationVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		CreateGeneralCommentMutation,
	);

	const experienceTracker = useContext(ExperienceTrackerContext);
	const { isReactionsEnabled } = useContext(ReactionsContext);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { cloudId } = useSessionData();
	const [contentId] = usePageContentId();
	const [contentType] = useContentType();
	const pageMode = useGetPageMode();
	const [{ searchSessionId }] = useSearchSessionId();
	const { hasContentChanged } = useCommentsContentState();
	const { onChange, resetContentChanged } = useCommentsContentActions();
	const { addNewCommentThreads } = useCommentsDataActions();
	const { updateReadCommentsListState } = useUnreadCommentsActions();
	const { scrollCommentsPanelToThread } = useCommentsPanelScroll();

	const expandCommentContainer = () => {
		setExpanded(true);
	};

	const closeCommentContainer = () => {
		setExpanded(false);
	};

	const handleCommentsPanelSave: CommentSaveHandler = (adfObject, onSuccess) => {
		const pageId = contentId || '';
		const { adf } = adfObject as any;

		return createGeneralCommentFn({
			variables: {
				pageId,
				cloudId,
				input: {
					commentBody: {
						value: JSON.stringify(adf),
						representationFormat: ContentRepresentation.ATLAS_DOC_FORMAT,
					},
					commentSource: Platform.WEB,
					containerId: pageId,
				},
			},
			update: updateApolloCacheParentCallback(
				'create',
				{
					pageId,
					contentStatus: ['DRAFT', 'CURRENT'] as GraphQLContentStatus[],
				},
				isReactionsEnabled,
			),
		}).then(({ data }: any) => {
			const commentResult = data.createFooterComment;
			const comment: CommentData = {
				...commentResult,
				replies: [], // not included in the payload
				isUnread: false,
				isOpen: true,
				type: CommentType.GENERAL,
			};

			updateReadCommentsListState((prev) => new Set([...prev, comment.id]));
			addNewCommentThreads({ inline: {}, general: { [comment.id]: comment } });

			onSuccess && onSuccess();
			resetContentChanged();
			closeCommentContainer();

			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'created',
					actionSubject: 'comment',
					actionSubjectId: comment.id, // The newly created comment ID
					objectType: contentType,
					objectId: contentId,
					source: AnalyticsSource.PAGE_FOOTER,
					attributes: {
						commentType: 'page',
						pageType: contentType,
						isLivePage: pageMode === PageMode.LIVE,
						editor: 'v2',
						searchSessionId,
						navdexPointType: END,
						parentCommentId: null, // analytics event schema type expects string or null
					},
				},
			}).fire();

			experienceTracker.succeed({
				name: ADD_PAGE_COMMENT_EXPERIENCE,
			});

			setTimeout(() => {
				scrollCommentsPanelToThread(comment.id);
			}, 1000);
		});
	};

	const handleCancel = ({ isEditorAborted }: { isEditorAborted: boolean }) => {
		const doCancel = () => {
			if (isEditorAborted) {
				shouldAutofocusPromptRef.current = true;
			}

			closeCommentContainer();
		};

		if (hasContentChanged) {
			resetContentChanged();
		}
		doCancel();
	};

	const handleOnEditorLoaded = () => {
		experienceTracker.succeed({
			name: ADD_PAGE_COMMENT_LOAD_EXPERIENCE,
		});
	};

	const { data: userData } = useQuery<UserQueryType, UserQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		UserQuery,
		{
			variables: { id: userId || '' },
			fetchPolicy: 'cache-and-network',
			// Skip the query if data is provided or the user is anonymous
			skip: !userId,
		},
	);

	const canViewProfile = true;

	const userInfo = {
		avatarUrl: userData?.user?.photos?.[0]?.value || '',
		accountId: userId,
		displayName: userData?.user?.displayName || '',
	};

	return (
		<Box id="addCommentButton" xcss={addCommentContainerStyles}>
			{!expanded ? (
				<Box xcss={addCommentClosedContainerStyles}>
					<ObjectAddCommentPrompt
						onClick={expandCommentContainer}
						autofocusRef={shouldAutofocusPromptRef}
					/>
				</Box>
			) : (
				<Box xcss={addCommentOpenContainerStyles}>
					<Box xcss={avatarStyles}>
						<ProfileAvatar
							canViewProfile={canViewProfile}
							src={userInfo.avatarUrl}
							userId={userInfo.accountId}
							isAnonymous={!userInfo.accountId}
							name={userInfo.displayName}
							testId="profile-avatar-comment"
						/>
					</Box>
					<Box xcss={addCommentEditorStyles}>
						<PageCommentEditor
							mode="create"
							saveHandler={handleCommentsPanelSave}
							cancelHandler={handleCancel}
							onEditorLoaded={handleOnEditorLoaded}
							onEditorContentChanged={onChange}
							useNewExperienceAbort
							expandEditor
							isCommentsPanel={false}
						/>
					</Box>
				</Box>
			)}
		</Box>
	);
};
