import React, { useContext, useState, useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { defineMessages, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	useCommentsData,
	CommentActionType,
	CommentType,
	type CommentData,
	type ReplyData,
} from '@confluence/comments-data';
import { ReactionsContext, useCommentsContentActions } from '@confluence/comment-context';
import { CommentBody, EditComment } from '@confluence/inline-comments-common';
import {
	handleResolveSuccess,
	handleMutationFailure,
} from '@confluence/inline-comments-common/entry-points/inlineCommentsUtils';
import type { CommentAction } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import { usePageContentId } from '@confluence/page-context';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import {
	CommentDeletionLocation,
	InlineCommentsQuery,
	ReopenCommentMutation,
} from '@confluence/inline-comments-queries';
import {
	ActiveCommentsQuery,
	type ActiveCommentsQueryType,
	type CommentFooterLocation,
	ResolveCommentMutation,
} from '@confluence/comments-panel-queries';
import { useInlineCommentQueryParams } from '@confluence/comment';
import type {
	CommentsPanelQueryType,
	InlineCommentAuthorUser,
	TopLevelComment,
	ReopenCommentMutationType,
	ReopenCommentMutationVariables,
	GraphQLContentStatus,
} from '@confluence/inline-comments-queries';
import {
	ExperienceTrackerContext,
	DELETE_PAGE_COMMENT_EXPERIENCE,
	RESOLVE_PAGE_COMMENT_EXPERIENCE,
} from '@confluence/experience-tracker';
import { PageMode } from '@confluence/page-utils/entry-points/enums';
import type { PageInfoNode } from '@confluence/page-info';
import type { FlagsStateContainer } from '@confluence/flags';
import { i18n as commentsI18n } from '@confluence/inline-comments-common/entry-points/i18n';
import { DeleteGeneralCommentMutation } from '@confluence/page-comments-queries/entry-points/DeleteGeneralCommentMutation.graphql';
import type {
	DeleteGeneralCommentMutationData,
	DeleteGeneralCommentMutationVariables,
} from '@confluence/page-comments-queries/entry-points/__types__/DeleteGeneralCommentMutation';
import {
	updateApolloCacheParentCallback as updateApolloCacheGeneralParentCallback,
	updateApolloCacheReplyCallback as updateApolloCacheGeneralReplyCallback,
} from '@confluence/page-comments-queries/entry-points/pageCommentUtils';
import { useSessionData } from '@confluence/session-data';
import { getLogger } from '@confluence/logger';
import {
	ViewValues,
	useCommentsPanel,
	useCommentsPanelScroll,
} from '@confluence/comments-panel-utils';
import { useShowCommentsPanel } from '@confluence/comments-panel';
import { AnalyticsSource } from '@confluence/comments-util/entry-points/analytics';

type CommentProps = {
	comment: CommentData | ReplyData;
	supportedTopLevelActions: CommentAction[];
	pageMode: PageMode;
	pageInfo: PageInfoNode | null;
	isUnread?: boolean;
	isHovered?: boolean;
	threadKey: string;
	flags?: FlagsStateContainer;
	adjustForNestedReplies?: boolean;
	hideBranchingStyle?: boolean;
	canAddComments?: boolean;
	isThreadHovered?: boolean;
	isParentCommentOpen?: boolean;
};

const i18n = defineMessages({
	commentReopenedFlagTitle: {
		id: 'object-comments.comment.reopened.flag.title',
		defaultMessage: 'Comment reopened',
		description:
			'Title to display in Flag to inform user when the comment was successfully reopened.',
	},
	commentReopenFailedFlagTitle: {
		id: 'object-comments.comment.reopen.failed.flag.title',
		defaultMessage: 'Failed to reopen comment',
		description: 'Title to display in Flag to inform user when the comment failed to reopen.',
	},
	retryCTA: {
		id: 'object-comments.comment.reopen.failed.flag.cta.retry',
		defaultMessage: 'Retry now',
		description:
			'CTA button text displayed in Error Flag for users to retry the failed comment reopen action.',
	},
});

const logger = getLogger('page-comments-queries');

// this component can be used for parent comment and a reply
export const ObjectComment = ({
	comment,
	supportedTopLevelActions,
	pageMode,
	pageInfo,
	isUnread = false,
	isHovered,
	threadKey,
	flags,
	adjustForNestedReplies = false,
	hideBranchingStyle = false,
	canAddComments = true,
	isThreadHovered,
	isParentCommentOpen,
}: CommentProps) => {
	const [editCommentId, setCommentForEdit] = useState('');
	const { isReactionsEnabled } = useContext(ReactionsContext);

	const experienceTracker = useContext(ExperienceTrackerContext);
	const { resetContentChanged } = useCommentsContentActions();
	const [contentId] = usePageContentId();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [
		{ commentsDataMap },
		{ addNewCommentThreads, deleteParentComment, handleRemovingComments },
	] = useCommentsData();
	const { cloudId } = useSessionData();

	const [
		{ currentlySelectedCommentMarkerRef },
		{ setCurrentView, setCurrentlySelectedCommentMarkerRef, setReplyToCommentId },
	] = useCommentsPanel();
	const { formatMessage } = useIntl();
	const { focusedCommentId } = useInlineCommentQueryParams();
	const { scrollCommentsPanelToThread } = useCommentsPanelScroll();
	const { isCommentsPanelShown, showCommentsPanel } = useShowCommentsPanel();

	const [resolveCommentFn] = useMutation(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ResolveCommentMutation,
	);
	const resolveCommentMutationFn = resolveCommentFn;

	const [reopenCommentFn] = useMutation<
		ReopenCommentMutationType,
		ReopenCommentMutationVariables
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations
	>(ReopenCommentMutation, {
		refetchQueries:
			// Copied from the existing dialog flow to support standalone experience
			pageMode === PageMode.VIEW
				? [{ query: InlineCommentsQuery, variables: { pageId: contentId } }]
				: [],
	});

	const [deleteGeneralCommentFn] = useMutation<
		DeleteGeneralCommentMutationData,
		DeleteGeneralCommentMutationVariables
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
	>(DeleteGeneralCommentMutation);

	// @ts-ignore FIXME: `contentId` can be `undefined` here, and needs proper handling
	const pageId: string = contentId;

	const userId = (comment?.author as InlineCommentAuthorUser)?.accountId;
	const topCommentUserAvatar = comment?.author?.profilePicture?.path;
	const topCommentDisplayName = comment?.author?.displayName ?? 'Anonymous';
	const commentId = comment?.id;
	const date = comment?.version?.when;
	const createdAtNonLocalized = comment?.createdAtNonLocalized;
	const versionDate = comment?.version?.when;
	const permissions = comment?.permissions;
	const content = comment?.body?.value;
	const permissionType = comment?.author?.permissionType ?? undefined;
	const parentCommentId = comment?.parentId;
	const isReply = !!parentCommentId;
	const isEdited = (comment?.version?.number || 0) > 1;
	const numReplies = (comment as TopLevelComment)?.replies?.length || 0;
	const commentUrl = comment?.links.webui ?? undefined;
	const inheritedReactionsData = comment?.reactionsSummary;

	const footerCommentLocation = comment?.location as CommentFooterLocation;

	const getResolvedProperties = useCallback(() => {
		const properties = footerCommentLocation?.commentResolveProperties;
		// resolve properties only show for resolved parent comments
		// once you reopen a comment, the resolveProperties will still be there so that it can continue to be
		// reused since we don't refetch data for that comment. this is why it's important to check if the comment is open or not
		if (!isReply && !(comment as CommentData).isOpen) {
			return properties?.resolved ? properties : undefined;
		}
		return undefined;
	}, [footerCommentLocation, isReply, comment]);

	const resolveProperties = getResolvedProperties();

	const isCommentRemovedByAnotherUser = comment?.wasRemovedByAnotherUser;

	const isResolvedByAnotherUser =
		isCommentRemovedByAnotherUser &&
		isCommentRemovedByAnotherUser === CommentActionType.RESOLVE_COMMENT_THREAD;
	const isDeletedByAnotherUser =
		isCommentRemovedByAnotherUser &&
		isCommentRemovedByAnotherUser === CommentActionType.DELETE_COMMENT;

	const handleDeleteGeneralComment = () => {
		let deleteLocation: CommentDeletionLocation | undefined;

		const deleteVariables = {
			variables: {
				commentIdToDelete: commentId,
				deleteFrom: deleteLocation,
			},
			update: isReply
				? updateApolloCacheGeneralReplyCallback(
						'delete',
						{
							pageId,
							contentStatus: ['DRAFT', 'CURRENT'] as GraphQLContentStatus[],
						},
						parentCommentId,
						isReactionsEnabled,
						commentId,
					)
				: updateApolloCacheGeneralParentCallback(
						'delete',
						{
							pageId,
							contentStatus: ['DRAFT', 'CURRENT'] as GraphQLContentStatus[],
						},
						isReactionsEnabled,
						commentId,
					),
		};

		deleteGeneralCommentFn({ ...deleteVariables })
			.then(({ data }) => {
				if (!data || !data.deleteComment) {
					throw new Error('No data returned from mutation');
				}

				resetContentChanged();

				if (isReply) {
					handleRemovingComments({
						threadKey,
						commentId,
						action: CommentActionType.DELETE_COMMENT,
						commentType: CommentType.GENERAL,
					});
				} else {
					deleteParentComment({
						threadKey,
						commentType: CommentType.GENERAL,
					});
				}

				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'deleted',
						actionSubject: 'comment',
						actionSubjectId: commentId,
						objectType: 'page',
						objectId: pageId,
						source: AnalyticsSource.COMMENTS_PANEL,
						attributes: {
							commentType: 'page',
							mode: pageMode,
							isReply,
						},
					},
				}).fire();

				experienceTracker.succeed({
					name: DELETE_PAGE_COMMENT_EXPERIENCE,
				});
			})
			.catch((err) => {
				void handleMutationFailure({
					experienceTracker,
					experienceName: DELETE_PAGE_COMMENT_EXPERIENCE,
					error: err,
				});
			});
	};

	const handleEditComment = () => {
		setCommentForEdit(commentId);
	};

	const exitCommentEditor = () => {
		setCommentForEdit('');
	};

	const handleReplyToComment = (id: string) => {
		setReplyToCommentId({ parentCommentId: id });
	};

	const handleReopenComment = () => {
		// NOTE: The reopen experience is started in CommentActions.tsx
		reopenCommentFn({
			variables: { commentId, cloudId },
		})
			.then(() => {
				// Update the state for panel experience
				const commentsDataMapClone = { ...commentsDataMap };
				const commentInMap = commentsDataMapClone[CommentType.GENERAL][threadKey];
				if (commentInMap) {
					commentInMap.isOpen = true;
					commentInMap.wasRemovedByAnotherUser = false;

					addNewCommentThreads(commentsDataMapClone);
				}

				// Show the reopen flag
				void flags?.showSuccessCircleFlag({
					id: commentId,
					title: formatMessage(i18n.commentReopenedFlagTitle),
					isAutoDismiss: true,
					actions: [
						{
							content: formatMessage(commentsI18n.goToCommentCTA),
							onClick: () => {
								if (!isCommentsPanelShown) {
									showCommentsPanel({ openWithView: ViewValues.OPEN });
								} else {
									setCurrentView(ViewValues.OPEN);
								}
								void flags.hideFlag(flags.state.flags[0]?.id);
							},
						},
						{
							content: formatMessage(commentsI18n.undoCTA),
							onClick: () => {
								handleResolveComment();
								void flags.hideFlag(flags.state.flags[0]?.id);
							},
						},
					],
				});
			})
			.catch(() => {
				void flags?.showErrorFlag({
					id: commentId,
					title: formatMessage(i18n.commentReopenFailedFlagTitle),
					isAutoDismiss: true,
					actions: [
						{
							content: formatMessage(i18n.retryCTA),
							onClick: () => {
								void flags.hideFlag(flags.state.flags[0]?.id);
								handleReopenComment();
							},
						},
					],
				});
			});
	};

	const handleResolveComment = () => {
		const mutationVariables = { commentIds: [commentId], cloudId };
		const query = ActiveCommentsQuery;

		// NOTE: The RESOLVED experience is started in CommentActions.tsx
		resolveCommentMutationFn({
			variables: mutationVariables,
			update: (cache) => {
				// readQuery can still return null which complicates TS lint errors with optional chaining
				// once we move to v3 of the apollo client we can move to cache.modify
				try {
					const dataProxy = cache.readQuery<ActiveCommentsQueryType>({
						query,
						variables: {
							pageId,
							contentStatus: ['DRAFT', 'CURRENT'] as GraphQLContentStatus[],
						},
					});

					const commentsToWrite = Object.assign({}, dataProxy?.comments);
					let totalCount = commentsToWrite.totalCount || 0;
					const commentsList = commentsToWrite.nodes?.filter(
						(n) => n !== null,
					) as TopLevelComment[];

					const idxToRemove = commentsList.findIndex((c) => c.id === commentId);

					// Remove the newly resolved comment from the list if we can find it
					if (idxToRemove !== -1) {
						commentsList.splice(idxToRemove, 1);
						totalCount--;
					}

					cache.writeQuery<ActiveCommentsQueryType | CommentsPanelQueryType>({
						query,
						variables: {
							pageId,
							contentStatus: ['DRAFT', 'CURRENT'] as GraphQLContentStatus[],
						},
						data: {
							comments: {
								nodes: commentsList,
								totalCount,
							},
						},
					});
				} catch (err) {
					logger.error`An error occurred when updating cache for comments panel resolve - ${err}`;
				}
			},
		})
			.then(() => {
				// Update the state for panel experience
				const commentsDataMapClone = { ...commentsDataMap };
				const commentInMap = commentsDataMapClone[CommentType.GENERAL][threadKey];

				if (commentInMap) {
					commentsDataMapClone[CommentType.GENERAL][threadKey].isOpen = false;

					addNewCommentThreads(commentsDataMapClone);
				}

				handleResolveSuccess({
					commentId,
					parentCommentMarkerRef: threadKey,
					pageId,
					pageMode,
					source: AnalyticsSource.COMMENTS_PANEL,
					createAnalyticsEvent,
					//getInlineNodeTypes  // TODO: Figure out how to get this info
				});

				void flags?.showSuccessCircleFlag({
					id: commentId,
					title: formatMessage(commentsI18n.resolvedFlagText),
					isAutoDismiss: true,
					actions: [
						{
							content: formatMessage(commentsI18n.goToCommentCTA),
							onClick: () => {
								if (!isCommentsPanelShown) {
									showCommentsPanel({ openWithView: ViewValues.RESOLVED });
								} else {
									setCurrentView(ViewValues.RESOLVED);
								}

								scrollCommentsPanelToThread(threadKey);

								void flags.hideFlag(flags.state.flags[0]?.id);
							},
						},
						{
							content: formatMessage(commentsI18n.undoCTA),
							onClick: () => {
								if (!isCommentsPanelShown) {
									showCommentsPanel({ openWithView: ViewValues.OPEN });
								}

								handleReopenComment();
								void flags.hideFlag(flags.state.flags[0]?.id);
							},
						},
					],
				});

				experienceTracker.succeed({
					name: RESOLVE_PAGE_COMMENT_EXPERIENCE,
				});
			})
			.catch((err) => {
				void handleMutationFailure({
					experienceTracker,
					experienceName: RESOLVE_PAGE_COMMENT_EXPERIENCE,
					error: err,
				});
			});
	};

	const pageType = pageInfo?.type ?? '';

	// Editor setup
	const spaceId = pageInfo?.space?.id ?? '';

	// User page permissions
	const operations = pageInfo?.operations || [];

	// The user can upload media only if they have update permissions for the page
	const hasMediaUploadPermissions = operations.some(
		(op) => op?.operation === 'update' && op?.targetType === pageType,
	);

	// We only want to focus resolved comments because the 'active' comments have
	// their own treatment for when one is hovered/selected and could cause weird behavior
	const isCommentFocused = resolveProperties?.resolved && commentId === focusedCommentId;

	const supportedActions =
		!isReply && isCommentRemovedByAnotherUser
			? (['reopen'] as CommentAction[])
			: supportedTopLevelActions;

	return commentId === editCommentId ? (
		<EditComment
			pageId={pageId}
			pageType={pageType}
			commentId={commentId}
			annotationId={threadKey}
			spaceId={spaceId}
			content={content ?? ''}
			isReply={isReply}
			displayCommentInViewMode={exitCommentEditor}
			avatarUrl={topCommentUserAvatar ?? ''}
			displayName={topCommentDisplayName}
			mode={pageMode === PageMode.VIEW ? 'view' : 'edit'}
			hasMediaUploadPermissions={hasMediaUploadPermissions}
			isCommentsPanel
			commentType={CommentType.GENERAL}
		/>
	) : (
		<CommentBody
			mode="view"
			annotationId={threadKey}
			userId={userId}
			avatarUrl={topCommentUserAvatar}
			displayName={topCommentDisplayName}
			date={date ?? ''}
			dateUrl={commentUrl ?? ''}
			createdAtNonLocalized={createdAtNonLocalized ?? ''}
			versionDate={versionDate ?? ''}
			pageId={pageId}
			pageType={pageType}
			commentId={commentId ?? ''}
			isReply={isReply}
			isEdited={isEdited}
			parentCommentId={parentCommentId}
			permissions={permissions}
			content={content ?? ''}
			permissionType={permissionType ?? undefined}
			supportedActions={supportedActions}
			deleteComment={handleDeleteGeneralComment}
			editComment={handleEditComment}
			resolveComment={handleResolveComment}
			numReplies={numReplies}
			isUnread={isUnread}
			isCommentActive
			isCommentsPanel
			isHovered={isHovered}
			isResolvedByAnotherUser={isResolvedByAnotherUser}
			isFocused={isCommentFocused}
			resolveProperties={resolveProperties}
			replyToComment={handleReplyToComment}
			inheritedReactionsData={inheritedReactionsData}
			isDeletedByAnotherUser={isDeletedByAnotherUser}
			currentlySelectedCommentMarkerRef={currentlySelectedCommentMarkerRef}
			setCurrentlySelectedCommentMarkerRef={setCurrentlySelectedCommentMarkerRef}
			commentType={CommentType.GENERAL}
			adjustForNestedReplies={adjustForNestedReplies}
			hideBranchingStyle={hideBranchingStyle}
			canAddComments={canAddComments}
			isThreadHovered={isThreadHovered}
			isParentCommentOpen={isParentCommentOpen}
		/>
	);
};
