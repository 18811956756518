import React, { useEffect, type FC } from 'react';

import { useCommentsPanelActions } from '@confluence/comments-panel-utils';

import { ObjectCommentContainer } from './ObjectCommentContainer';

type CommentTypes = {
	showGeneralComment?: boolean;
};

export const ObjectCommentWrapper: FC<CommentTypes> = ({ showGeneralComment }) => {
	const { setShowGeneralComments } = useCommentsPanelActions();

	useEffect(() => {
		setShowGeneralComments(showGeneralComment);
		// eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to set the comment type once on the comment mount
	}, []);

	return <ObjectCommentContainer />;
};
